
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../../style/grids.scss';
@use '../../../style/icons.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.bt-card-hide {
  display: none;
}
.adtocalendar-wrapper {
  display: inline-block;
  height: 43px;
  height: auto;
  width: auto;
  vertical-align: middle;
}
